import React from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import TitleAndTheme from '../components/TitleAndTheme';
import ProjectHeader from '../components/ProjectHeader';
import Grid from '../components/Grid';
import AssetWithCaption from '../components/AssetWithCaption';
import Text from '../components/Text';
import Border from '../components/Border';

const Styleguides = () => (
  <Layout>
    <TitleAndTheme
      title="Edwin Morris, Genius styleguides project"
      themeClass="theme--styleguides"
    />

    <ProjectHeader
      name="Styleguides"
      subhead="Standardizing the design process"
    />

    <Text>
      <p>
        In my first three years at Genius, I introduced lots of software,
        templates, and guides to improve the design, handoff, and implementation
        processes.
      </p>

      <h2>Design Process</h2>

      <p>
        I introduced Sketch and Origami, and created a library of components,
        plugins, and data files to make rote tasks easier. This transformed
        mockups at Genius from rough illustrator files with lots of cropped
        screenshots and duplication, to high fidelity mockups and prototypes
        that take less time to make.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="full"
        video="styleguides/sketch_timelapse_data_and_symbols"
        width="1600"
        height="1000"
      >
        Using a plugin to quickly populate a list of real songs into a mockup.
      </AssetWithCaption>
    </Grid>

    <Text>
      <p>
        Using Sketch templates and a library of symbols decreased project
        startup time, gave us more fine-grained control over every element,
        improved each mockup’s consistency with the real product, and made it
        easy to hand off a project to another designer.
      </p>
      <p>
        As the engineering and mobile teams grew, the design team was less and
        less likely to implement its designs. I introduced Zeplin and Origami to
        improve the way we communicated our design choices to other teams.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="left_half"
        video="styleguides/origami_demo"
        width="728"
        height="728"
      >
        Even the simplest Origami prototype is a whole level above a static
        mockup from Sketch.
      </AssetWithCaption>

      <AssetWithCaption
        position="right_half"
        video="styleguides/zeplin_demo"
        width="750"
        height="750"
      >
        Zeplin saved us a ton of time and gave app developers better specs than
        we ever did manually.
      </AssetWithCaption>
    </Grid>

    <Text noBottomMargin>
      <p>
        Origami allowed us to spend more time in the design phase figuring out
        how things should behave, and gave us a great preview tool on iOS so
        interactive prototypes could be passed around at meetings, before
        implementation ever began.
      </p>

      <h2>Implementation Process</h2>

      <p>
        A few months after I started, we released a new brand and design. Behind
        the scenes, we also overhauled the song page front-end with Angular and
        a new approach to CSS.
      </p>
      <p>
        I introduced a variant of BEM syntax and wrote a styleguide detailing
        how to do everything from order properties to organize files. When I
        started, Genius’ CSS was hard to reuse, nested under body classes, and
        used to enforce permissions by showing or hiding page elements. I pushed
        to move logic and high-level states out of CSS and into JS, and now
        Genius’ CSS is highly componentized and reusable.
      </p>
    </Text>

    <HeadlineWithBorder>
      <Border />
      <Headline>Styleguide Excerpts</Headline>
    </HeadlineWithBorder>

    <Text noBottomMargin>
      <h2>Rules Of Thumb</h2>
      <ul>
        <li>
          Avoid using extends: they change the order of rendered CSS selectors
          in an unintuitive way, and there are caveats when using them inside
          media queries and mixins.
        </li>
        <li>
          Avoid using ids in selectors: they have different specificity rules
          than class selectors, which forces you to use unnecessarily specific
          selectors to override them.
        </li>
        <li>
          Avoid using element selectors: these are the least efficient selector
          — especially at the end of long selector strings like `.class .class
          .class.class element`, which forces the browser to search for every
          `element` and then filter that list.
        </li>
        <li>Avoid nesting more than three levels deep with SCSS.</li>
      </ul>

      <h2>What Is A Component</h2>
      <ul>
        <li>
          When adding new HTML/CSS or editing existing elements on the site,
          always think of how parts of the element can be extracted into their
          own discrete units.
        </li>
        <li>
          A component should be the smallest possible unit that still makes
          sense: this often means a component will represent a tiny element,
          like the user badge, but it can also mean a component will represent
          an element which is large, and contains many other components, like a
          layout.
        </li>
        <li>
          A component should contain *just enough* css such that it can be
          included anywhere without having to override any default styles.
        </li>
      </ul>

      <h2>Component Naming Conventions</h2>
      <ul>
        <li>
          Names should be based on the component’s visual role, not its content
          e.g. text_label, not song_lyrics_label.
        </li>
        <li>The syntax of naming:</li>
        <ul>
          <code>component_name</code>
          <br />
          <code>component_name-descendant</code>
          <br />
          <code>component_name-descendant-descendant</code>
          <br />
          <code>component_name--modifier</code>
          <br />
          <code>component_name-descendant--modifier</code>
        </ul>
        <li>Words within a component name are separated by underscores.</li>
        <li>Descendant components are separated by dashes.</li>
        <li>
          You can continue to stack descendants to whatever reasonable length —
          more than three is probably too many, and you should reconsider your
          component or break its children into separate components.
        </li>
        <li>
          Modifier classes use two dashes — modifiers can’t be used alone, they
          must always be paired with a root class.
        </li>
      </ul>
      <h2>Using SCSS Variables</h2>
      <ul>
        <li>
          Name a variable after its role, not its value e.g. primary_color, not
          brand_yellow.
        </li>
        <li>Use underscores to separate words.</li>
        <li>Use a name which is specific to the variable’s current use.</li>
        <li>
          Only use the same variables across items which make sense to change as
          a group i.e. not items that incidentally use the same value, but items
          that on principle must use the same value.
        </li>
      </ul>

      <h2>Using z-index</h2>
      <ul>
        <li>
          Add a layer name and corresponding z-index value to the master list in{' '}
          <code>imports/z_index_variables.scss</code>.
        </li>
        <li>
          Access the layer via the <code>z_index()</code> mixin.
        </li>
        <li>
          If the layer to which you want to add a z-index is within a z-index
          context, create a nested map inside the master list in{' '}
          <code>z_index_variables</code> and end its name with{' '}
          <code>_context</code>.
        </li>
        <li>
          If you’re not sure whether your layer is in an explicit or implicit
          context, or what a z-index context is at all,{' '}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href="https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Positioning/Understanding_z_index/The_stacking_context"
          >
            read this
          </a>{' '}
          — it explains how z-index contexts are created.
        </li>
      </ul>

      <h2>Organizing SCSS Files</h2>
      <ul>
        <li>
          All component and import filenames should start with an underscore and
          separate words with an underscore.
        </li>
        <li>
          All import filenames should end with their type e.g.{' '}
          <code>_x_variables.scss</code> or <code>_y_mixins.scss</code>.
        </li>
        <li>
          Imports can only contain non-rendering scss e.g. <code>@mixins</code>,{' '}
          <code>%extends</code>, or <code>$variables</code>, otherwise they’ll
          render their contents as many times as they’re imported.
        </li>
        <li>
          Import filenames shouldn’t be too specific or too broad e.g.{' '}
          <code>imports/_helper_mixins.scss</code> doesn’t indicate the role of
          its contents very clearly, and{' '}
          <code>imports/_song_page_padding_variables.scss</code> is too specific
          and can’t be reused.
        </li>
        <li>
          Components should be named after the main class they style e.g.{' '}
          <code>_leaderboard.scss</code> should style the{' '}
          <code>.leaderboard</code> component and all its modifiers and
          descendants.
        </li>
        <li>
          Components should not include the title of their folder e.g. prefer{' '}
          <code>mobile/_leaderboard.scss</code> to{' '}
          <code>mobile/_mobile_leaderboard.scss</code>.
        </li>
        <li>
          Cross-platform components which have some platform-specific features
          should be named the same thing in all three folders e.g.{' '}
          <code>mobile/_leaderboard.scss</code>,{' '}
          <code>desktop/_leaderboard.scss</code>, and{' '}
          <code>shared/_leaderboard.scss</code> — in these cases, the shared
          version should be the master reference, with the platform-specific
          overrides/additions being as small as possible.
        </li>
      </ul>
    </Text>
  </Layout>
);

export default Styleguides;

const HeadlineWithBorder = styled(Grid)`
  margin: var(--vertical-space) 0 0;
  overflow: hidden;
  position: relative;
`;

const Headline = styled.h2`
  grid-column: left-text-start / left-text-end;
`;
