import styled from 'styled-components';

const Border = styled.div`
  border-bottom: 1px solid var(--theme-primary);
  bottom: 0;
  left: -100vw;
  position: absolute;
  width: 200vw;
`;

export default Border;
